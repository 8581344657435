



















































































































































































































































































































































































































































































































































import Vue from 'vue'
import Card from '@/components/Card.vue'
import 'vue2-datepicker/index.css'
import moment from 'moment-timezone'
import AdsManagerFilter from '@/components/ADSManager/MainComponent/AdsManagerFilter.vue'
import Select from '@/components/BaseComponents/BaseSelect/SelectTableColumns.vue'
import TargetContentLocationNew from '@/components/ADSManager/Dashboard/CurrentTarget/TargetContentLocationNew.vue'
import TargetContentDetailed from '@/components/ADSManager/Dashboard/CurrentTarget/TargetContentDetailed.vue'
import TargetContentPlacements from '@/components/ADSManager/Dashboard/CurrentTarget/TargetContentPlacements.vue'
import NavBar from '@/components/ADSManager/MainNavbarComponent/MainNavbar.vue'
import BasePagination from '@/components/BaseComponents/BasePagination/BasePagination.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import BaseDatePicker from '@/components/BaseComponents/BaseDatePicker/BaseDatePicker.vue'
import LivePreview from '@/components/ADSManager/NewCampaign/AdDesign/LivePreview.vue'

export default Vue.extend({
  name: 'AllAdsTable',
  components: {
    Card,
    // TargetContentNew,
    TargetContentLocationNew,
    TargetContentDetailed,
    TargetContentPlacements,
    Select,
    BasePagination,
    AdsManagerFilter,
    BasePagePreloader,
    NavBar,
    BaseDatePicker,
    // eslint-disable-next-line vue/no-unused-components
    LivePreview
  },
  props: {
    campaign_id: {
      required: true
    }
  },
  data () {
    return {
      componentKey: 0,
      idAdPreview: null,
      openPrev: false,
      page: 1,
      coefficient: null,
      adSet: [],
      ad: [],
      getFiltersRequest: false,
      loading: true,
      namesAdAccounts: null,
      dateRange: JSON.parse(localStorage.getItem('dateRangeAllAds')) || [
        moment()
          .subtract(7, 'd')
          .format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY')
      ]
    }
  },
  mounted () {
    // this.get_campaign_targeting(this.campaign_id)
    this.load_campaign({ campaignId: this.campaign_id, params: {} })
    this.get_report_adSets({ id: this.campaign_id })
    const localFilters = JSON.parse(localStorage.getItem('filtersTypeAd_set'))

    if (localFilters) {
      this.setFilters(localFilters)
    }
    this.getAdSetRequest()
  },
  computed: {
    ...mapGetters('adsManager', [
      'getFilter',
      'getAllAds_AdSets',
      'getCampaign',
      'getDeliveriesApi',
      'getSelectedAdAccount'
    ]),
    ...mapGetters('adsManager/campaignDashboard', [
      'getLocaleName'
    ]),
    ...mapGetters('allAds', [
      'getAdPreview',
      'getListPreview'
    ]),
    ...mapGetters('adAccounts', [
      'getById'
    ]),
    ...mapGetters('filter', [
      'getFilterPayload'
    ]),
    ad_account_id () {
      return this.getCampaign?.account_id
    },
    currency () {
      return this.getById(this.ad_account_id)?.currency || ''
    },
    currency_factor () {
      return this.getById(this.ad_account_id)?.currency_factor || null
    },
    campaign_name () {
      return this.getCampaign?.name
    },
    timezone () {
      return this.getSelectedAdAccount?.timezone
    }
  },

  methods: {
    ...mapActions('adsManager', [
      'load_campaign',
      '_setFilter',
      'load_campaign_targeting',
      'get_report_adSets',
      'delete_adSet_ad',
      'update_status_adSet_ad',
      'load_campaign_review',
      'load_allAds_adSets'
    ]),
    ...mapActions('adsManager/campaignDashboard', [
      'get_report_adSets'
    ]),
    ...mapActions('allAds', ['load_ad_preview', 'load_list_preview']),
    ...mapMutations('filter', ['setDateFilters', 'setFilters']),
    ...mapMutations('filter', ['setDateFilter']),
    genderName (number) {
      if (number === 0) {
        return 'Male and Female'
      }
      if (number === 1) {
        return 'Male'
      }
      if (number === 2) {
        return 'Female'
      }
    },
    convertDetailedTargeting (adSet) {
      if (adSet.formatted_targeting.inclusion_targeting || adSet.formatted_targeting?.exclusion_targeting) {
        return [{
          inclusion_targeting: adSet.formatted_targeting.inclusion_targeting,
          exclusion_targeting: adSet.formatted_targeting.exclusion_targeting
        }]
      } else {
        return []
      }
    },
    getFilteredLocation (locations, include_status) {
      return {
        countries: locations
          .filter(v => v.is_included === include_status)
          .filter((l) => l.type === 'country'),
        cities: locations
          .filter(v => v.is_included === include_status)
          .filter((l) => l.type === 'city')
          .sort((f, s) => f.country !== s.country ? f.country > s.country : f.region > s.region),
        others: locations
          .filter(v => v.is_included === include_status)
          .filter((l) => l.type !== 'city' && l.type !== 'country')
      }
    },
    /*
    periodChange (range) {
      this.dateRange[0] = range[0]
      this.dateRange[1] = range[1]
      localStorage.setItem('dateRangeAllAds', JSON.stringify(range))
      this.getAdSetRequest()
    },
    */

    periodChange (date): void {
      this.setDateFilters([
        {
          field: 'START_DATE',
          value: date[0],
          predicate: null
        },
        {
          field: 'STOP_DATE',
          value: date[1],
          predicate: null
        }
      ])
      localStorage.setItem('dateRangeAllAds', JSON.stringify(date))
      this.getAdSetRequest()
    },
    loadAdPreview (idAd, placements) {
      this.idAdPreview = idAd
      const rqLoadList = {
        campaignId: this.campaign_id,
        payload: {
          devices: [],
          platforms: [],
          positions: []
        }
      }
      if (placements.devices) {
        placements.devices.forEach(el => rqLoadList.payload.devices.push(el))
      }
      if (placements.platforms) {
        placements.platforms.forEach(el =>
          rqLoadList.payload.platforms.push(el)
        )
      }
      if (placements.positions && Object.keys(placements.positions).length > 0) {
        for (const key in placements.positions) {
          placements.positions[key].forEach(el =>
            rqLoadList.payload.positions.push(el)
          )
        }
      }
      rqLoadList.payload.devices = [...new Set(rqLoadList.payload.devices)]
      rqLoadList.payload.platforms = [...new Set(rqLoadList.payload.platforms)]
      rqLoadList.payload.positions = [...new Set(rqLoadList.payload.positions)]
      Promise.resolve(this.load_list_preview(rqLoadList)).then(() => {
        this.$modal.show(
          LivePreview,
          {
            lists: this.getListPreview,
            idAd: this.idAdPreview,
            campaignId: this.campaign_id,
            singleAd: true,
            on_close: this.hide_modal
          },
          {
            width: 900,
            name: 'live-preview-modal',
            adaptive: true,
            height: 'auto',
            overlayTransition: 'linear'
          }
        )
      })
    },
    closeModalPrev () {
      this.openPrev = false
    },

    getPlacements (placements) {
      const result = []
      const upperCase = (key, place) => {
        return (
          placements[key][place][0].toUpperCase() +
          placements[key][place].slice(1)
        )
      }

      for (const key in placements) {
        const device = placements[key].device ? upperCase(key, 'device') : ''
        const platform = placements[key].platform
          ? upperCase(key, 'platform')
          : ''
        const position = placements[key].position
          ? upperCase(key, 'position')
          : ''
        const string = `${device} ${platform} ${position}`
        result.push({ ad_format: string.replace(/\s+/g, ' ').trim() })
      }
      return result
    },

    async switchPage (pageNum: string) {
      this.page = pageNum || 1
      this.getAdSetRequest()
    },

    handleSelectAll (isSelected: boolean): void {
      this.adSet = []

      const updateStatusData = this.getAllAds_AdSets

      if (isSelected === true) {
        updateStatusData.forEach(adSet => {
          this.adSet.push(+adSet.id)
          adSet.selected = true
          adSet.ads.forEach(ad => {
            this.ad.push(+ad.id)
            ad.selected = true
          })
        })
      }

      if (isSelected === false) {
        updateStatusData.forEach(adSet => {
          this.adSet = []
          this.ad = []
          adSet.selected = false
          adSet.ads.forEach(ad => (ad.selected = false))
        })
      }
    },

    handleSelectAllAd (ads, isSelectedAdSet: boolean, idAdSet: number) {
      // * проверяем есть ли переданный айдишник(idAdSet) в нашем массиве this.adSet
      const checkEntryId = (): boolean => {
        return this.adSet.some(
          (currentIdAdSet: string) => +currentIdAdSet === +idAdSet
        )
      }

      if (!checkEntryId() && isSelectedAdSet === true) this.adSet.push(+idAdSet)

      if (isSelectedAdSet === true) {
        ads.forEach(ad => {
          ad.selected = true
          this.ad.push(+ad.id)
        })
      }

      if (isSelectedAdSet === false) {
        this.adSet.splice(this.adSet.indexOf(idAdSet), 1)
        ads.forEach(ad => {
          this.ad.splice(this.ad.indexOf(ad.id), 1)
          ad.selected = false
        })
      }
    },

    getDataCampaign (idAd: number, isSelected: boolean, adSet) {
      const checkEntryIdAds = (): boolean => {
        return this.ad.some(
          (currentId: string) => Number(currentId) === Number(idAd)
        )
      }

      if (!checkEntryIdAds() && isSelected === true) {
        this.ad.push(Number(idAd))
      }
      if (checkEntryIdAds() && isSelected === false) {
        this.ad.splice(this.ad.indexOf(Number(idAd)), 1)
      }

      // * Если хоть 1 реклама в текущем adSet равна false, то ставим чекбокс у adSet в false, и удаляем adSet из массива this.adSet
      if (adSet.ads.some(ad => ad.selected === false)) {
        this.adSet.splice(this.adSet.indexOf(adSet.id), 1)
        adSet.selected = false
      } else {
        // * если все рекламы в текущем adSet true, то ставим чекбокс adSet = true, и добавляем в массив this.adSet
        adSet.selected = true
        this.adSet.push(Number(adSet.id))
      }
    },

    isMainChecked () {
      return (
        this.getAllAds_AdSets.selected &&
        !this.getAllAds_AdSets?.some(adSet => adSet.selected === false)
      )
    },

    isAdSetChecked (updateStatus) {
      return (
        updateStatus.selected &&
        !updateStatus.ads.some(ad => ad.selected === false)
      )
    },

    checkLimits (max: number, min: number, budget: number): string {
      if (typeof max === 'number' && typeof min === 'number') {
        return `${max} - ${min} ${this.currency}`
      }
      if (typeof budget === 'number') return `${budget} ${this.currency}`
      return 'Something went wrong while counting'
    },

    chooseImgStatus (status: string) {
      switch (status) {
        case 'ACTIVE':
          return 'ACTIVE'
        case 'ERROR':
          return 'ERROR'
        case 'PAUSED':
          return 'PAUSED'
        case 'DELETED':
          return 'DELETED'
        default:
          return 'ERROR'
      }
    },

    run (idAdSet: Array<number>, ads, idAd: string) {
      const checkIdAdSet = idAdSet ? [Number(idAdSet)] : []
      const checkIdAd = idAd ? [Number(idAd)] : []
      this.update_status_adSet_ad({
        idCampaign: this.campaign_id,
        requestBody: {
          status: 'run',
          ids_ad_set: this.adSet.length > 0 ? this.adSet : checkIdAdSet,
          ids_ad:
            this.ad.length > 0 ? this.ad : ads?.map(ad => ad.id) || checkIdAd
        }
      }).then(() => {
        this.adSet = []
        this.ad = []
        this.getAdSetRequest()
      })
    },

    stop (idAdSet: Array<number>, ads, idAd: string) {
      this.update_status_adSet_ad({
        idCampaign: this.campaign_id,
        requestBody: {
          status: 'pause',
          ids_ad_set: this.adSet.length > 0 ? this.adSet : [+idAdSet] || [],
          ids_ad:
            this.ad.length > 0
              ? this.ad
              : ads?.map(ad => ad.id) || [+idAd] || []
        }
      }).then(() => {
        this.adSet = []
        this.ad = []
        this.getAdSetRequest()
      })
    },

    deleteAd (idAdSet: Array<number>, ads, idAd: string) {
      this.delete_adSet_ad({
        idCampaign: this.campaign_id,
        requestBody: {
          ids_ad_set: this.adSet.length > 0 ? this.adSet : [+idAdSet] || [],
          ids_ad:
            this.ad.length > 0
              ? this.ad
              : ads?.map(ad => ad.id) || [+idAd] || []
        }
      }).then(() => {
        this.adSet = []
        this.ad = []
        this.getAdSetRequest()
      })
    },

    checkInsights (allAds) {
      return allAds[0]?.ad_set_insights !== null
    },
    /*
    getAdSetRequest () {
      this.loading = true

      const checkDate = index => {
        if (this.dateRange[index] === '' || this.dateRange[index]) {
          return this.dateRange[index]
        } else if (index === 0) {
          return this.$moment()
            .subtract(7, 'd')
            .format('DD.MM.YYYY')
        } else {
          return this.$moment().format('DD.MM.YYYY')
        }
      }

      this.changeFieldDate({
        startDate: checkDate(0),
        endDate: checkDate(1)
      })
      this.changeFieldPage(this.page)

      const promise = [this.load_allAds_adSets(this.getRequestBody)]
      Promise.all(promise)
        .then(() => {
          this.loading = false
        })['catch'](() => this.$router.push({ name: '404' }))
    },
    */
    getAdSetRequest () {
      this.loading = true

      this.load_allAds_adSets({
        ad_account_id: this.campaign_id,
        filters: this.getFilterPayload,
        page: this.page
      }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
})
